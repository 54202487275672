import Auth from 'services/auth';
import { reduxStore } from 'constants/reduxStore';
import { ALLOWED_SCOPES, ADMIN, SUPER_ADMIN, BILLER, SALES, COMPASS } from 'constants/roles';

import Router from 'next/router';
import LocalStorageUtils from 'utils/localStorage';
import routes from 'routes';
import initApollo from 'utils/apollo';
import { getUserIdentifiers } from 'operations/queries/user';

import { AUTH_USER_IDENTIFIERS_LOADED } from './types';
import { redirectToCompass } from 'modules/shared/utils/redirectToCompass';

// TODO: Deprecate. The Apollo client is already initialized and available in the App's component
// tree via a provider. We shouldn't try initializing it more than once.
async function getIdentifiers() {
  const apollo = initApollo();
  const id = LocalStorageUtils.getUserId();

  return apollo
    .query({
      query: getUserIdentifiers,
      variables: { id },
    })
    .then(({ data }) => data);
}

/**
 *
 * Actions.
 *
 */

export async function verifyToken() {
  await Auth.verifyToken();

  return getIdentifiers();
}

export async function signIn(email, password) {
  const res = await Auth.signIn(email, password, ALLOWED_SCOPES);
  const { scopes, data } = res;

  if (scopes.includes(ADMIN) || scopes.includes(SUPER_ADMIN)) {
    Router.replace(routes.Home.to, routes.Home.as);

    const userIdentifiers = await getIdentifiers();
    reduxStore.dispatch(setUserIdentifiers(userIdentifiers));
  } else if (scopes.includes(SALES)) {
    Router.replace(routes.DemoAccountList.to, routes.DemoAccountList.as);
  } else if (scopes.some((scope) => scope.includes(COMPASS))) {
    redirectToCompass('/');
  }
}

export function forgotPassword(email, redirect) {
  const encodedEmail = encodeURIComponent(email);
  return Auth.forgotPassword(encodedEmail, redirect);
}

export function resetPassword(email, password, token) {
  return Auth.resetPassword(email, password, token);
}

export function logout() {
  Auth.logout();
}

export function mfaSignIn(challenge, mfaToken, email, password) {
  return Auth.mfaSignIn(challenge, mfaToken, email, password, ALLOWED_SCOPES).then((res) => {
    if (res.scopes.includes(ADMIN) || res.scopes.includes(SUPER_ADMIN)) {
      Router.replace(routes.Home.to, routes.Home.as);
      return;
    } else if (res.scopes.some((scope) => scope.includes(COMPASS))) {
      redirectToCompass('/');
    }

    return;
  });
}

export function setUserIdentifiers(userIdentifiers) {
  return {
    type: AUTH_USER_IDENTIFIERS_LOADED,
    userIdentifiers,
  };
}
